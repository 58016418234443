import { useEffect } from "react";
import Box from "@mui/material/Box";
import { get, set } from "../../services/cache";
import config from "../../services/config";
import { useNavigate } from "react-router-dom";
import { dateAdd } from "../../services/utils";
import Loading from "../../components/Loading/Loading";

export const FedidLogin = () => {
  const redirect = useNavigate();
  const code = new URL(window.location.href).searchParams.get("code");
  const code_verifier = get(config.FedidCodeVerifier.cacheKey);

  useEffect(() => {
    const urlDecoded = {
      grant_type: "authorization_code",
      client_id: process.env.REACT_APP_CLIENTID,
      code,
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      code_verifier: code_verifier.data,
    };

    const urlEncoded = Object.entries(urlDecoded)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    fetch(process.env.REACT_APP_FEDID_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: urlEncoded,
    })
      .then((response) => response.json())
      .then((res) => {
        const expires = dateAdd(new Date(), "second", 7199).toISOString();
        set({
          key: config.FedidLogin.cacheKey,
          data: res,
          expires,
        });

        fetch(`${config.baseUrl}/v1/users/login`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${res.access_token}`,
            "x-api-key": config.apiKey,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            set({
              key: "userCache",
              data: data.data,
            });
            window.location.href = "/homepage";
          })
          .catch(() => {
            console.error(
              "Unable To Connect Server! (Check if there is any maintenance)"
            );
            redirect("/");
          });
      })
      .catch(() => {
        console.error(
          "Unable To Connect Server! (Check if there is any maintenance)"
        );
        redirect("/");
      });
  }, []);

  return (
    <Box>
      <Loading />
    </Box>
  );
};
