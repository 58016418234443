import { Container } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTranslation } from "react-i18next";

export const MaintenancePage = () => {
  const { t } = useTranslation();

  return (
    <Container className="maintenance-container" maxWidth={true}>
      <div className="sub-container">
        <div className="maintenance-icon rotate">
          <SettingsIcon className="maintenance-icon" sx={{ fontSize: 140 }} />
        </div>
        <div className="error-msg-container">
          <h1>{t("MaintenanceTitle")}</h1>
          <p>{t("MaintenanceDesc")}</p>
        </div>
      </div>
    </Container>
  );
};

export default MaintenancePage;
