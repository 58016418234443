import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { get } from "../../services/cache";
import { useTranslation } from "react-i18next";

export const AdminPage = () => {
  const { t } = useTranslation();
  const redirect = useNavigate();
  const loginInfo = get("userCache");

  const isAdmin = loginInfo?.data?.hasRole;
  const isCaptain = loginInfo?.data?.hasDuty;
  const isModerator = loginInfo?.data?.has_moderator_role;

  useEffect(() => {
    if (!isCaptain && !isAdmin && !isModerator) {
      redirect("/homepage");
    }
  }, [redirect, isCaptain, isAdmin, isModerator]);

  const getPageTitle = () => {
    if (isAdmin && isCaptain) return t("AdminPanel");
    if (isCaptain && loginInfo.data.site === "813") return t("MerterCaptain");
    if (isCaptain) return t("CaptainPanel");
    if (isAdmin) return t("AdminPanel");
    if (isModerator) return t("ModeratorPanel");
    return t("ForbiddenArea");
  };

  const pageTitle = getPageTitle();

  const buttonConfigs = [
    { label: t("ShowUnitsLabel"), url: "/showunits" },
    { label: t("AddGuestLabel"), url: "/addguest" },
    ...(isCaptain || isAdmin ? [{ label: t("DrillManagementLabel"), url: "/drillmanagement" }] : []),
    ...(isAdmin ? [{ label: t("UpdateLocationListLabel"), url: "/initstores" }] : []),
  ];

  return (
    <Box>
      <h1 className="welcomeText">{pageTitle}</h1>
      <div className="loginContainer">
        <div className="admin-buttons">
          {buttonConfigs.map((config, index) => (
            <button
              key={index}
              className="ui button fedidLogin active"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = `${config.url}`;
              }}
            >
              {config.label}
            </button>
          ))}
          <button
            className="ui button returnToStoreSelection"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = `/homepage`;
            }}
          >
            {t("Return")}
          </button>
        </div>
      </div>
    </Box>
  );
};
