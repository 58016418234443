export const get = (key) => {
  const cacheKey = `${key}`;
  let cachedItem = JSON.parse(localStorage.getItem(cacheKey));
  if (cachedItem && new Date(cachedItem.expires) < new Date()) {
    cachedItem = null;
  }
  return cachedItem;
};

export const remove = (key) => {
  const cacheKey = `${key}`;
  localStorage.removeItem(cacheKey);
};

export const set = (cacheData) => {
  const cacheKey = `${cacheData.key}`;
  remove(cacheKey);
  localStorage.setItem(
    cacheKey,
    JSON.stringify({ data: cacheData.data, expires: cacheData.expires })
  );
};

export const clear = () => {
  localStorage.clear();
};
