import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "../../services/cache";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import config from "../../services/config";
import { useTranslation } from "react-i18next";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import GetAppIcon from "@mui/icons-material/GetApp";
import apiClient from "../../services/apiClient";

export const DrillReport = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  // GET USER INFO
  const userAccessToken = get("fedidLoginAccessToken");

  // STATES
  const [drillDetails, setDrillDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleDownloadClick = async (id) => {
    try {
      const response = await fetch(
        `${config.baseUrl}/v1/drills/${id}/participants/file`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userAccessToken.data.access_token}`,
            "x-api-key": config.apiKey,
          },
          credentials: "same-origin",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `drill_report_${id}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    apiClient
      .get(`/v1/drills/${id}/participants`)
      .then((data) => {
        setDrillDetails(data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [id]);

  return (
    <Box>
      <h1 className="welcomeText">{t("DrillReport")}</h1>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </div>
      ) : drillDetails.length > 0 ? (
        <div>
          <Button
            className="downloadButton"
            onClick={(e) => {
              e.preventDefault();
              handleDownloadClick(id);
            }}
            sx={{
              display: "flex",
              backgroundColor: "#007bff",
              color: "white",
              fontWeight: "700",
              padding: "10px 20px",
              margin: "12px auto",
              "&:hover": {
                backgroundColor: "#0056b3",
              },
            }}
            startIcon={<GetAppIcon fontSize="small" />}
          >
            {t("DownloadDrillReport")}
          </Button>
          <div className="userListContainer">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="usersTable"
              >
                <TableHead>
                  <TableRow className="userListRow">
                    <TableCell>{t("UserID")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {drillDetails.map((participant, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <strong>
                          {participant.name}{" "}
                          {participant.surName}{". "}
                        </strong>
                        {participant.fireTraining ? (
                          <LocalFireDepartmentIcon className="redBackground" />
                        ) : (
                          ""
                        )}{" "}
                        {participant.firstAid ? (
                          <MedicalServicesIcon className="greenBackground" />
                        ) : (
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      ) : (
        <p className="storeEmpty">{t("NobodyHasJoinedToDrill")}</p>
      )}
      <button
        className="ui button returnToStoreSelection showUnitsReturnBut"
        onClick={(e) => {
          e.preventDefault();
          window.location.href = `${config.siteUrl}/drills`;
        }}
      >
        {t("Return")}
      </button>
    </Box>
  );
};
