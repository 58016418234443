import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import generateHash from "../../services/generateHash";
import config from "../../services/config";
import { dateAdd } from "../../services/utils";
import { set } from "../../services/cache";
import TechLogo from "../../assets/logo/dkt-tech-logo-old.png";
import AppLogo from "../../assets/img/inout-logo.svg";
import { useTranslation } from "react-i18next";

export const Login = () => {
  const [fedidUrl, setFedidUrl] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const crypto = generateHash();

    set({
      key: config.FedidCodeVerifier.cacheKey,
      data: crypto,
      expires: dateAdd(new Date(), "second", 7199).toISOString(),
    });

    let url = `${process.env.REACT_APP_LOGIN_URL}&code_challenge=${crypto}&code_challenge_method=plain`;

    setFedidUrl(url);
  }, []);

  return (
    <Box>
      <div className="loginContainer welcomeContainer">
        <div className="buttonArea">
          <img src={AppLogo} alt="Inout App Logo" className="appLogo" />
          <span className="containerTitle">IN-OUT APP </span>
          <Button className="loginButton" variant="contained" href={fedidUrl}>
            {t("DecathlonLogin")}
          </Button>
        </div>
        <div className="logoContainer">
          <img
            className="techLogo"
            src={TechLogo}
            alt="Decathlon Technology Logo"
          />
        </div>
      </div>
    </Box>
  );
};
