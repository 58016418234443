import { useEffect } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { get } from "../../services/cache";
import config from "../../services/config";
import LinearProgress from "@mui/material/LinearProgress";
import { useTranslation } from "react-i18next";
import apiClient from "../../services/apiClient";

export const InitStores = () => {
  const { t } = useTranslation();
  const redirect = useNavigate();

  useEffect(() => {
    const loginInfo = get("userCache");

    const { hasDuty, hasRole, has_moderator_role } = loginInfo.data;

    if (!(hasDuty || hasRole || has_moderator_role)) {
      redirect("/homepage");
    }

    apiClient
      .get("/v1/business_unit/list/units/initialize")
      .then(() => {
        setTimeout(() => {
          redirect(config.managementPath);
        }, 2000);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <Box>
      <div className="initArea">
        <div className="initStoresContainer">
          <p>{t("LocationsUpdated")}</p>
          <p> {t("ReturnToPreviousPage")}</p>
          <Box sx={{ width: "100%", mt: 3, mb: 4 }}>
            <LinearProgress />
          </Box>
          <button
            className="ui button fedidLogin"
            onClick={() => {
              window.location.href = `${config.managementPath}`;
            }}
          >
            {t("Return")}
          </button>
        </div>
      </div>
    </Box>
  );
};
