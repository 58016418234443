import CookieConsent from "react-cookie-consent";
import config from "../../services/config";
import { useTranslation } from "react-i18next";

const WarnUser = ({ getKvkkData }) => {
  const { t } = useTranslation();

  const sendKvkkAccept = () => {
    getKvkkData(false);
  };

  return (
    <div>
      <CookieConsent
        disableStyles
        location="none"
        buttonText={t("IUnderstand")}
        cookieName="warnUserNotice"
        overlay
        expires={0}
        overlayClasses="overlayclass"
        onAccept={sendKvkkAccept}
      >
        <div className="warnBox">
          <h1>{t("ReadCarefully")}</h1>
          <p>{t("ReadCarefullyDesc")}</p>
        </div>
        <br />
        <button
          className="ui button returnToStoreSelection returnToAdmin"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = `${config.managementPath}`;
          }}
        >
          {t("Return")}
        </button>
      </CookieConsent>
    </div>
  );
};

export default WarnUser;
