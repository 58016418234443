import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { get } from "../../services/cache";
import ExitFromStore from "../../components/InoutScreens/ExitFromStore";

export const Exit = () => {
  const redirect = useNavigate();

  useEffect(() => {
    const loginInfo = get("userCache");
    const userAccessToken = get("fedidLoginAccessToken");

    if (!loginInfo || !userAccessToken) {
      redirect("/");
    }

    const hasDuty = loginInfo.data.hasDuty;
    const hasRole = loginInfo.data.hasRole;
    const isModerator = loginInfo?.data?.has_moderator_role;

    if (!hasDuty && !hasRole && !isModerator) {
      redirect("/homepage");
    }
  }, []);

  return <ExitFromStore />;
};
