import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "../pages/Login/Login";
import { FedidLogin } from "../pages/Login/FedidLogin";
import { Homepage } from "../pages/Homepage/Homepage";
import { Exit } from "../pages/Homepage/DoExit";
import { AdminPage } from "../pages/AdminPages/AdminPage";
import { ShowUnits } from "../pages/AdminTools/ShowUnits";
import { InitStores } from "../pages/AdminTools/InitStores";
import { AddGuest } from "../pages/AdminTools/AddGuest";
import { StartDrill } from "../pages/AdminTools/StartDrill";
import { DrillList } from "../pages/AdminTools/DrillList";
import { DrillReport } from "../pages/AdminTools/DrillReport";
import { NotFound } from "../pages/NotFound/NotFound";
import { MaintenancePage } from "../pages/Maintenance/Maintenance";
import { DrillManagementPanel } from "../pages/AdminPages/DrillManagementPanel";
import { Easter } from "../pages/Easter/Easter";
import PrivateRoute from "../services/PrivateRoute";
import "../App.css";

const MasterRouter = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<FedidLogin />} />
      <Route path="/maintenance" element={<MaintenancePage />} />
      <Route path="/easter" element={<Easter />} />
      <Route path="*" element={<NotFound />} />

      {[
        { path: "/homepage", component: <Homepage /> },
        { path: "/mgmtpnl", component: <AdminPage /> },
        { path: "/showunits", component: <ShowUnits /> },
        { path: "/initstores", component: <InitStores /> },
        { path: "/addguest", component: <AddGuest /> },
        { path: "/exit", component: <Exit /> },
        { path: "/drillmanagement", component: <DrillManagementPanel /> },
        { path: "/drills", component: <DrillList /> },
        { path: "/startdrill", component: <StartDrill /> },
        { path: "/drill/:id", component: <DrillReport /> },
      ].map(({ path, component }) => (
        <Route
          key={path}
          path={path}
          element={<PrivateRoute>{component}</PrivateRoute>}
        />
      ))}
    </Routes>
  </Router>
);

export default MasterRouter;
