import Box from "@mui/material/Box";
import SettingsIcon from "@mui/icons-material/Settings";
import Divider from "@mui/material/Divider";
import { Text } from "../Text/Text";
import config from "../../services/config";
import { get, remove } from "../../services/cache";
import { useTranslation } from "react-i18next";
import TechLogo from "../../assets/logo/dkt-tech-logo-old.png";

export const ExitFromStore = (exitProps) => {
  const { t } = useTranslation();

  // GET USERS INFORMATION
  const loginInfo = get("userCache");
  const userAccessToken = get("fedidLoginAccessToken");

  // CHECK USERS CURRENT LOCATION
  const whereIAm = get("whereIAm");

  const exitFromStore = () => {
    // DELETE USERS DIFFERENT LOCATION STORAGE INFOS
    remove("whereIAm");
    remove("differentSite");

    fetch(`${config.baseUrl}/v1/business_unit/exit`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userAccessToken.data.access_token}`,
        "x-api-key": config.apiKey,
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .catch((error) => console.log(error));
  };

  return (
    <Box>
      <h1 className="hp-welcome-text">
        {t("SuccessfulEnterance")},{" "}
        {exitProps.loginInfo &&
          exitProps.loginInfo.data.name + " " + exitProps.loginInfo.data.sn}
      </h1>
      <div className="loginContainer">
        <div className="buttonArea">
          <span className="containerTitle">{t("CanExitHere")}</span>
          <Divider sx={{ mb: 3 }} />
          {(exitProps.isAdmin ||
            exitProps.isCaptain ||
            exitProps.isModerator) && (
              <button
                className="adminSettings"
                onClick={() => {
                  window.location.href = `${config.managementPath}`;
                }}
              >
                <SettingsIcon className="svgIcons" />
              </button>
            )}

          <div className="locationFlex currentLocationDiv">
            <p className="currentLocationText">{t("YouAreHere")}</p>
            <Text
              text={
                loginInfo?.data?.differentStore === 1
                  ? `${whereIAm?.data}`
                  : `${loginInfo?.data?.current_site_name} - ${loginInfo?.data?.current_site_id}`
              }
            />
          </div>
          <button
            className="ui button red exitButton"
            onClick={(e) => {
              e.preventDefault();
              exitFromStore();
              setTimeout(() => {
                window.location.href = `${config.siteUrl}`;
              }, 1000);
            }}
          >
            {t("ExitFromLocation")}
          </button>
        </div>
        <div className="logoContainer">
          <img
            className="techLogo"
            src={TechLogo}
            alt="Decathlon Technology Logo"
          />
        </div>
      </div>
    </Box>
  );
};

export default ExitFromStore;
