import CookieConsent from "react-cookie-consent";
import config from "../../services/config";
import { useTranslation } from "react-i18next";

const WarnAddGuest = () => {
  const { t } = useTranslation();

  return (
    <div>
      <CookieConsent
        disableStyles
        location="none"
        buttonText="Başka Bir Misafir Ekle"
        buttonStyle={{ display: "none" }}
        cookieName="WarnAddGuest"
        overlay
        overlayClasses="overlayclass"
      >
        <div className="warnBox">
          <h1>{t("GuestAdded")}</h1>
          <p>{t("GuestAddedDesc")}</p>
        </div>
        <br />
        <button
          className="ui button returnToStoreSelection returnToAdmin"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = `${config.siteUrl}/addguest`;
          }}
        >
          {t("Okay")}
        </button>
      </CookieConsent>
    </div>
  );
};

export default WarnAddGuest;
