import easterImage from '../../assets/img/easter.png';
import config from "../../services/config";
import { useTranslation } from "react-i18next";


export const Easter = () => {
    const { t } = useTranslation();

    return (
        <div className="App">
            <div className="centered">
                <img src={easterImage} className='easterPhoto' alt="Easter Photo" />
            </div>
            <button
                className="ui button returnToUserlist"
                onClick={() => {
                    window.location.href = `${config.siteUrl}/homepage`;
                }}
            >
                {t("Return")}
            </button>
        </div>

    );
}

export default Easter;
