// INOUT API PROVIDER - CUSTOM REQUESTS COULD BE ADDED
// THIS FILE IS CUSTOM API PROVIDER FILE TO MAKE BETTER AND EFFICIENT CONNECTIONS BETWEEN API SERVICES

import config from "./config";
import { get } from "./cache";

const sendRequest = async (url, method, data) => {
  try {
    const userAccessToken = get("fedidLoginAccessToken");

    // If the user token is lost, empty or disfunctional, send the user back to root to log in
    if (!userAccessToken?.data?.access_token) {
      window.location.href = "/";
    }

    const headers = {
      "x-api-key": config.apiKey,
      Authorization: `Bearer ${userAccessToken.data.access_token}`,
      "Content-Type": "application/json",
    };

    const requestOptions = {
      method,
      headers,
    };

    if (method === "POST" || method === "PUT") {
      requestOptions.body = JSON.stringify(data);
    }

    const response = await fetch(`${config.baseUrl}${url}`, requestOptions);

    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Request failed");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const apiClient = {
  post: (url, data) => sendRequest(url, "POST", data),
  get: (url) => sendRequest(url, "GET"),
  patch: (url, data) => sendRequest(url, "PATCH", data),
  put: (url, data) => sendRequest(url, "PUT", data),
  delete: (url) => sendRequest(url, "DELETE"),
};

export default apiClient;
