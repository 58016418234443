import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import { get } from "../../services/cache";
import config from "../../services/config";
import WarnUser from "../../components/NoticeScreens/WarnUser";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import { Modal } from '@mui/material';
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useTranslation } from "react-i18next";
import apiClient from "../../services/apiClient";
import { AddGuestModal } from "../../components/AddGuestModal/AddGuestModal";

const _loginInfo = get("userCache");
const userAccessToken = get("fedidLoginAccessToken");
const userCache = get("userCache");

// CHECK USERS CURRENT LOCATION
const usersCurrentSite = userCache?.data?.site;

export const ShowUnits = () => {
  const { t } = useTranslation();
  const redirect = useNavigate();

  const [users, setUsers] = useState([]);
  const [guests, setGuests] = useState([]);
  const [stores, setStores] = useState([]);
  const [store, setStore] = React.useState("");
  const [fireBadge, setFireBadge] = useState(0);
  const [firstAidBadge, setFirstAidBadge] = useState(0);
  const [unitCode, setUnitCode] = useState("");
  const [isThereDrill, setIsThereDrill] = useState(false);
  const [drillParticipants, setDrillParticipants] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // GET USERS LOCATION INFO
  let getDefaultLocation = _loginInfo?.data?.site_name;
  let getDefaultUnitCode = _loginInfo?.data?.site;

  // GET USER ROLE
  let isAdmin = _loginInfo?.data?.hasRole;
  let isCaptain = _loginInfo?.data?.hasDuty;
  let isSuperUser = _loginInfo?.data?.hasRole || _loginInfo?.data?.hasDuty;

  const kvkkDataHandler = () => {
    loadUserList();

    if (isAdmin || isCaptain) {
      getActiveDrills();
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };


  const isUserInDrill = (userId) => {
    return drillParticipants.includes(userId);
  };

  const checkDrillParticipants = async (drillId) => {
    try {
      const response = await fetch(
        `${config.baseUrl}/v1/drills/${drillId}/participants`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userAccessToken.data.access_token}`,
            "x-api-key": config.apiKey,
          },
          credentials: "same-origin",
        }
      );

      const data = await response.json();

      setDrillParticipants(data.data.map((participant) => participant.userId));
    } catch (error) {
      console.error(error);
    }
  };

  const getActiveDrills = () => {
    fetch(`${config.baseUrl}/v1/drills?unitCode=${usersCurrentSite}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userAccessToken.data.access_token}`,
        "x-api-key": config.apiKey,
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((res) => {
        setIsThereDrill(res?.data[0]?.drillStatuses == "Active");
        checkDrillParticipants(res?.data[0].id);
      })
      .catch((error) => console.log(error));
  };

  const changeStore = (event) => {
    setStore(event.target.value);
  };

  const loadUserList = () => {
    // CALL USER LIST ON LOCATION
    apiClient
      .get("/v1/business_unit/list/users")
      .then((data) => {
        setIsLoading(false);

        let nonGuests = data.data.filter((g) => g.guest === false);
        setUsers(nonGuests);

        let onlyGuests = data.data.filter((g) => g.guest === true);
        setGuests(onlyGuests);
      })
      .catch((error) => console.log(error));
  };

  const badgeCounter = (unitCode) => {
    // CALL BADGES
    apiClient
      .get(`/v1/business_unit/badges?unitCode=${unitCode}`)
      .then((data) => {
        setFireBadge(data.data.fireBadge);
        setFirstAidBadge(data.data.firstAidBadge);
      })
      .catch((error) => console.log(error));
  };

  const kickFromStore = (kick) => {
    apiClient
      .post(`/v1/business_unit/list/users/${kick}/remove`)
      .catch((error) => console.log(error));
  };

  const selectThisStore = (location) => {
    setUnitCode(location);

    const differentLocation = location;

    apiClient
      .get(`/v1/business_unit/list/users?unitCode=${location}`)
      .then((data) => {
        let nonGuests = data.data.filter((g) => g.guest === false);
        setUsers(nonGuests);

        let onlyGuests = data.data.filter((g) => g.guest === true);
        setGuests(onlyGuests);
      })
      .catch((error) => console.log(error));

    badgeCounter(location);

    fetch(`${config.baseUrl}/v1/drills?unitCode=${differentLocation}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userAccessToken.data.access_token}`,
        "x-api-key": config.apiKey,
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((res) => {
        setIsThereDrill(res?.data[0]?.drillStatuses == "Active");
        checkDrillParticipants(res?.data[0].id);
      })
      .catch((error) => console.log(error));
  };

  const kickGuest = (kickGuest) => {
    apiClient
      .post(`/v1/business_unit/list/users/${kickGuest}/remove?guest=true`)
      .catch((error) => console.log(error));
  };

  const joinDrill = (userId, unitCode) => {
    const selectedUnitCode = store
      ? store.unitCode
      : unitCode || getDefaultUnitCode;

    apiClient
      .get(`/v1/drills?unitCode=${selectedUnitCode}`)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          const drillId = response.data[0].id;

          // NEW VER - CARRY DATA WITH BODY
          const requestBody = {
            userId: userId,
            drillId: drillId,
          };

          apiClient
            .post(`/v1/drills/join`, requestBody)
            .then(() => {
              // NEW USER (ID) ADDED TO DRILL - Then update Participants Array (State)
              const newParticipants = [...drillParticipants, userId];
              setDrillParticipants(newParticipants);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    const loginInfo = get("userCache");

    const hasDuty = loginInfo.data.hasDuty;
    const hasRole = loginInfo.data.hasRole;
    const isModerator = loginInfo?.data?.has_moderator_role;

    if (!hasDuty && !hasRole && !isModerator) {
      redirect("/homepage");
    }

    // CALL OTHER STORES LIST
    if (hasRole) {
      apiClient
        .get("/v1/business_unit/list")
        .then((data) => {
          setStores(data);
        })
        .catch((error) => console.log(error));
    }

    // CALL BADGES COUNTER
    badgeCounter(loginInfo.data.site);
  }, [redirect]);


  return (
    <Box>
      <WarnUser getKvkkData={kvkkDataHandler} />
      <h1 className="welcomeText">
        {!store.name ? getDefaultLocation : store.name} {t("SpecificLocation")}
      </h1>

      {users.length > 0 && (
        <div className="counter-area">
          <div className="counter-item">
            <MedicalServicesIcon />
            <span>{firstAidBadge}</span>
          </div>
          <div className="counter-item">
            <LocalFireDepartmentIcon />
            <span>{fireBadge}</span>
          </div>
        </div>
      )}

      {(users.length > 0 && (firstAidBadge < 2 || fireBadge < 2)) && (
        <div className="popup-container">
          <div className="popup-staff">
            {" "}
            <p className="popup-text">
              {t("BadgeAlert")}
            </p>
          </div>
        </div>
      )}

      {isAdmin && (
        <div className="selectOtherStore">
          <FormControl
            className="otherStore"
            fullWidth
            variant="filled"
            sx={{ m: 1, minWidth: 120 }}
          >
            <InputLabel id="demo-simple-select-filled-label">
              {t("SelectLocation")}
            </InputLabel>

            <Select
              id="demo-simple-select-filled"
              labelId="demo-simple-select-filled-label"
              value={store}
              label={t("SelectLocation")}
              onChange={changeStore}
            >
              {stores.map((magaza, index) => (
                <MenuItem
                  key={index}
                  value={magaza}
                  onClick={() => {
                    selectThisStore(magaza.unitCode);
                  }}
                >
                  {magaza.name.toUpperCase()} - {magaza.unitCode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}

      {!isLoading ? (
        users.length > 0 ? (
          <div className="userListContainer">
            <TableContainer component={Paper} style={{ maxHeight: 280 }}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="usersTable"
              >
                <TableHead>
                  <TableRow className="userListRow">
                    <TableCell align="left">{t("UserName")}</TableCell>
                    <TableCell align="left">{t("Date")}</TableCell>
                    {isSuperUser && (
                      <TableCell align="center">{t("RemoveFromStore")}</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": {} }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 700,
                        }}
                      >
                        {row.userName} {row.userSurname}{" "}
                        {row.fire ? (
                          <LocalFireDepartmentIcon className="redBackground" />
                        ) : (
                          ""
                        )}{" "}
                        {row.first_aid ? (
                          <MedicalServicesIcon className="greenBackground" />
                        ) : (
                          ""
                        )}
                        {isThereDrill && (
                          <div className="margin-l-auto">
                            {isUserInDrill(row.id) ? (
                              <button className="ui button green joinDrill">
                                {t("AlreadyJoinedToDrill")}
                              </button>
                            ) : (
                              <button
                                className="ui button darkRed joinDrill"
                                onClick={() => {
                                  joinDrill(row.id, unitCode);
                                }}
                              >
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20px"
                                    height="20px"
                                    className="joinDrillButtonSvg"
                                    viewBox="0 0 16 16"
                                    version="1.1"
                                  >
                                    <path
                                      fill="#fff"
                                      d="M14 6h-1.7c-0.2 0-0.4-0.1-0.6-0.2l-1.3-1.3c-0.2-0.3-0.6-0.5-1.1-0.5h-0.3c1.1 0 2-0.9 2-2s-0.9-2-2-2-2 0.9-2 2c0 0.7 0.4 1.4 1 1.7l-0.2 0.3h-2c-1.1 0-2.3 0.5-3 1.5l-0.6 0.8c-0.4 0.4-0.2 1 0.2 1.3 0.4 0.2 0.9 0.1 1.2-0.3l0.5-0.7c0.3-0.4 0.7-0.6 1.2-0.6h0.8l-0.7 1.6c-0.3 0.6-0.4 1.2-0.4 1.9v2c0 0.3-0.2 0.5-0.5 0.5h-2.5c-0.6 0-1 0.4-1 1s0.4 1 1 1h3.5c0.8 0 1.5-0.7 1.5-1.5v-2.5l3.8 4.5c0.6 0.9 1.7 1.5 2.8 1.5h0.9l-5.4-6.7c-0.3-0.4-0.2-0.8 0-1.3l0.6-1.5 0.7 0.8c0.4 0.4 1 0.7 1.6 0.7h2c0.6 0 1-0.4 1-1s-0.4-1-1-1z"
                                    />
                                  </svg>
                                </div>
                                {t("JoinDrill")}
                              </button>
                            )}
                          </div>
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="timeText"
                        sx={{ textWrap: "nowrap" }}
                      >
                        {row.actionTime.substring(0, 10) +
                          " - " +
                          row.actionTime.replace(
                            /^[^:]*([0-2]\d:[0-5]\d).*$/,
                            "$1"
                          )}
                      </TableCell>
                      {isSuperUser && (
                        <TableCell align="right">
                          <LogoutSharpIcon
                            className="exitUserButton"
                            onClick={() => {
                              kickFromStore(row.id);
                              setTimeout(() => {
                                window.location.href = `${config.siteUrl}/showunits`;
                              }, 800);
                            }}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <p className="storeEmpty">{t("LocationIsEmpty")}</p>
        )
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="suspenseLoadingSvg"
            viewBox="0 0 16 16"
            version="1.1"
          >
            <path
              fill="#fff"
              d="M14 6h-1.7c-0.2 0-0.4-0.1-0.6-0.2l-1.3-1.3c-0.2-0.3-0.6-0.5-1.1-0.5h-0.3c1.1 0 2-0.9 2-2s-0.9-2-2-2-2 0.9-2 2c0 0.7 0.4 1.4 1 1.7l-0.2 0.3h-2c-1.1 0-2.3 0.5-3 1.5l-0.6 0.8c-0.4 0.4-0.2 1 0.2 1.3 0.4 0.2 0.9 0.1 1.2-0.3l0.5-0.7c0.3-0.4 0.7-0.6 1.2-0.6h0.8l-0.7 1.6c-0.3 0.6-0.4 1.2-0.4 1.9v2c0 0.3-0.2 0.5-0.5 0.5h-2.5c-0.6 0-1 0.4-1 1s0.4 1 1 1h3.5c0.8 0 1.5-0.7 1.5-1.5v-2.5l3.8 4.5c0.6 0.9 1.7 1.5 2.8 1.5h0.9l-5.4-6.7c-0.3-0.4-0.2-0.8 0-1.3l0.6-1.5 0.7 0.8c0.4 0.4 1 0.7 1.6 0.7h2c0.6 0 1-0.4 1-1s-0.4-1-1-1z"
            />
          </svg>
        </div>
      )}

      <h1 className="welcomeText">{t("Guests")}<Button startIcon={<PersonAddIcon sx={{ ml: 0.8, fill: '#1976d2' }} />} sx={{
        ml: 1.4,
        fontWeight: 600,
        bgcolor: '#fff',
        '&:hover': {
          bgcolor: '#eaebeb',
        }
      }
      } onClick={handleOpenModal}>{t("OpenAddGuestModal")}</Button></h1>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="addguest-modal"
        aria-describedby="addguest-modal-onpage"
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.50)'
        }}
      >
        <Box sx={{ ...modalStyle }}>
          <AddGuestModal />
        </Box>
      </Modal>
      {!isLoading ? (
        guests.length > 0 ? (
          <div className="guestListContainer">
            <TableContainer component={Paper} style={{ maxHeight: 290 }}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="usersTable"
              >
                <TableHead>
                  <TableRow className="userListRow">
                    <TableCell>{t("NameSurname")}</TableCell>
                    <TableCell align="left" className="hiddenRow">
                      {t("GuestNumber")}
                    </TableCell>
                    <TableCell align="center">{t("RemoveFromStore")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {guests.map((guest, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {guest.userName.toUpperCase()} {guest.userSurname.toUpperCase()}
                        {isThereDrill && (
                          <div className="margin-l-auto">
                            {isUserInDrill(guest.id) ? (
                              <button className="ui button green joinDrill">
                                {t("AlreadyJoinedToDrill")}
                              </button>
                            ) : (
                              <button
                                className="ui button darkRed joinDrill"
                                onClick={() => joinDrill(guest.id, unitCode)}
                              >
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20px"
                                    height="20px"
                                    className="joinDrillButtonSvg"
                                    viewBox="0 0 16 16"
                                    version="1.1"
                                  >
                                    <path
                                      fill="#fff"
                                      d="M14 6h-1.7c-0.2 0-0.4-0.1-0.6-0.2l-1.3-1.3c-0.2-0.3-0.6-0.5-1.1-0.5h-0.3c1.1 0 2-0.9 2-2s-0.9-2-2-2-2 0.9-2 2c0 0.7 0.4 1.4 1 1.7l-0.2 0.3h-2c-1.1 0-2.3 0.5-3 1.5l-0.6 0.8c-0.4 0.4-0.2 1 0.2 1.3 0.4 0.2 0.9 0.1 1.2-0.3l0.5-0.7c0.3-0.4 0.7-0.6 1.2-0.6h0.8l-0.7 1.6c-0.3 0.6-0.4 1.2-0.4 1.9v2c0 0.3-0.2 0.5-0.5 0.5h-2.5c-0.6 0-1 0.4-1 1s0.4 1 1 1h3.5c0.8 0 1.5-0.7 1.5-1.5v-2.5l3.8 4.5c0.6 0.9 1.7 1.5 2.8 1.5h0.9l-5.4-6.7c-0.3-0.4-0.2-0.8 0-1.3l0.6-1.5 0.7 0.8c0.4 0.4 1 0.7 1.6 0.7h2c0.6 0 1-0.4 1-1s-0.4-1-1-1z"
                                    />
                                  </svg>
                                </div>
                                {t("JoinDrill")}
                              </button>
                            )}
                          </div>
                        )}
                      </TableCell>
                      <TableCell align="left" className="hiddenRow">
                        {guest.id}
                      </TableCell>
                      <TableCell align="right">
                        <LogoutSharpIcon
                          className="exitUserButton"
                          onClick={() => {
                            kickGuest(guest.id);
                            setTimeout(() => {
                              window.location.href = `${config.siteUrl}/showunits`;
                            }, 800);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <p className="storeEmpty">{t("GuestListEmpty")}</p>
        )
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="suspenseLoadingSvg"
            viewBox="0 0 16 16"
            version="1.1"
          >
            <path
              fill="#fff"
              d="M14 6h-1.7c-0.2 0-0.4-0.1-0.6-0.2l-1.3-1.3c-0.2-0.3-0.6-0.5-1.1-0.5h-0.3c1.1 0 2-0.9 2-2s-0.9-2-2-2-2 0.9-2 2c0 0.7 0.4 1.4 1 1.7l-0.2 0.3h-2c-1.1 0-2.3 0.5-3 1.5l-0.6 0.8c-0.4 0.4-0.2 1 0.2 1.3 0.4 0.2 0.9 0.1 1.2-0.3l0.5-0.7c0.3-0.4 0.7-0.6 1.2-0.6h0.8l-0.7 1.6c-0.3 0.6-0.4 1.2-0.4 1.9v2c0 0.3-0.2 0.5-0.5 0.5h-2.5c-0.6 0-1 0.4-1 1s0.4 1 1 1h3.5c0.8 0 1.5-0.7 1.5-1.5v-2.5l3.8 4.5c0.6 0.9 1.7 1.5 2.8 1.5h0.9l-5.4-6.7c-0.3-0.4-0.2-0.8 0-1.3l0.6-1.5 0.7 0.8c0.4 0.4 1 0.7 1.6 0.7h2c0.6 0 1-0.4 1-1s-0.4-1-1-1z"
            />
          </svg>
        </div>
      )}


      <button
        className="ui button returnToStoreSelection showUnitsReturnBut"
        onClick={(e) => {
          e.preventDefault();
          window.location.href = `${config.managementPath}`;
        }}
      >
        {t("Return")}
      </button>
    </Box >
  );
};

const modalStyle = {
  width: '100%',
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};