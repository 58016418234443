import { useState } from "react";
import {
  Box,
  IconButton,
  Drawer,
  MenuItem,
  Select,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Menu from "@mui/icons-material/Menu";
import Home from "@mui/icons-material/Home";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "/node_modules/flag-icons/css/flag-icons.min.css";

import styles from "./Sidebar.module.css";

export default function Sidebar() {
  const { t, i18n } = useTranslation();

  // SIDEBAR STATES
  const [open, setOpen] = useState(false);

  // GET APP VERSION
  const appVersion = process.env.REACT_APP_VERSION;

  const toggleSlider = () => {
    setOpen(!open);
  };

  // LANGUAGE HANDLER
  const handleLanguageChange = async (event) => {
    const selectedLanguage = event.target.value;
    await i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);
  };

  return (
    <span className="sidebarNav">
      <IconButton
        style={{
          backgroundColor: "#0074af",
          borderRadius: "0 0 20px 0",
          padding: "20px 24px",
        }}
        size="large"
        onClick={toggleSlider}
      >
        <Menu />
      </IconButton>
      <Drawer open={open} anchor="left" onClose={toggleSlider}>
        <Box className={styles.menuSliderContainer} component="div">
          <Box className={styles.langSelector}>
            <Stack
              style={{ marginBottom: "1rem" }}
              spacing={2}
              direction="column"
            >
              {window.location.pathname !== '/' && window.location.pathname !== '/homepage' && (
                <Button
                  onClick={() => {
                    history.back();
                  }}
                  style={{ fontWeight: "700" }}
                  variant="outlined"
                >
                  <ArrowBackIcon style={{ fill: "#1976d2", marginRight: "4px" }} />{" "}
                  {t("PreviousPage")}
                </Button>
              )}
              {window.location.pathname !== '/' && (
                <Button
                  onClick={() => {
                    window.location.href = `/homepage`;
                  }}
                  style={{ fontWeight: "700" }}
                  variant="outlined"
                >
                  <Home style={{ fill: "#1976d2", marginRight: "4px" }} />{" "}
                  {t("Homepage")}
                </Button>
              )}
            </Stack>
            <Typography variant="span" color="black">
              {t("SelectLanguage")}
            </Typography>
            <Select
              value={i18n.resolvedLanguage}
              onChange={handleLanguageChange}
              sx={{
                display: "flex",
                alignItems: "center",
                ml: "1rem",
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  ml: "1rem",
                },
              }}
            >
              <MenuItem value="en">
                <span
                  style={{ marginRight: "6px" }}
                  className="fi fi-us"
                ></span>
                <Typography variant="span" color="black">
                  English
                </Typography>
              </MenuItem>
              <MenuItem value="tr">
                <span
                  style={{ marginRight: "6px" }}
                  className="fi fi-tr"
                ></span>
                <Typography variant="span" color="black">
                  Türkçe
                </Typography>
              </MenuItem>
            </Select>
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#007dbc",
            padding: "12px",
          }}
        >
          <Typography variant="span" color="white">
            InOut App v{appVersion}
          </Typography>
          <Typography variant="span" color="white">
            @Decathlon Türkiye
          </Typography>
        </Box>
      </Drawer>
    </span>
  );
}
