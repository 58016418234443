import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import MasterRouter from "./router/Router";
import Sidebar from "./components/Sidebar/Sidebar";
import darkBG from "./assets/img/inout-dark.jpeg";
import lightBG from "./assets/img/inout-light.jpg";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "./i18n";

// CREATE THE THEME
const theme = createTheme({
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

// CHANGE BACKGROUND
let currentTime = new Date().getHours();
if (document.body) {
  if (8 <= currentTime && currentTime < 16) {
    document.body.style.backgroundImage = `url(${lightBG})`;
  } else {
    document.body.style.backgroundImage = `url(${darkBG})`;
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Sidebar />
    <MasterRouter />
  </ThemeProvider>
);

reportWebVitals();
