import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import config from "../../services/config";
import { useTranslation } from "react-i18next";
import apiClient from "../../services/apiClient";

export const DrillList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [drills, setDrills] = useState([]);
  const [filteredDrills, setFilteredDrills] = useState([]);
  const [filterText, setFilterText] = useState("");

  const calculateTimeDifference = (activationTime, completedTime) => {
    const differenceInMillis =
      new Date(completedTime) - new Date(activationTime);
    const hours = Math.floor(differenceInMillis / 3600000);
    const minutes = Math.floor((differenceInMillis % 3600000) / 60000);
    const seconds = Math.floor((differenceInMillis % 60000) / 1000);

    const timeParts = [];
    if (hours > 0) timeParts.push(`${hours} ${t("Hour")}`);
    if (minutes > 0) timeParts.push(`${minutes} ${t("Minutes")}`);
    if (seconds > 0) timeParts.push(`${seconds} ${t("Seconds")}`);

    return timeParts.join(" ");
  };

  useEffect(() => {
    apiClient
      .get("/v1/drills/completed")
      .then((data) => {
        setDrills(data?.data);
        setFilteredDrills(data?.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleRowClick = (id) => {
    navigate(`/drill/${id}`);
  };

  const handleFilterChange = (value) => {
    const searchText = value.target.value;
    setFilterText(searchText);
    const filteredDrills = drills.filter((row) =>
      row.unitCode.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredDrills(filteredDrills);
  };

  const sortedDrills = [...filteredDrills].sort((a, b) =>
    new Date(b.activationTime) - new Date(a.activationTime)
  );

  return (
    <Box>
      <h1 className="welcomeText">{t("ChooseADrill")}</h1>
      <Box
        className="filterByUnitCode"
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "1rem 0",
          color: "#fff",
        }}
      >
        <TextField
          label={t("FilterByUnitCode")}
          value={filterText}
          onChange={handleFilterChange}
        />
      </Box>
      <div className="userListContainer">
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="usersTable"
          >
            <TableHead>
              <TableRow className="userListRow">
                <TableCell>{t("DrillListUnitCode")}</TableCell>
                <TableCell>{t("DrillListAddedBy")}</TableCell>
                <TableCell>{t("DrillActivationTime")}</TableCell>
                <TableCell>{t("DrillCompletionTime")}</TableCell>
                <TableCell>{t("DrillDurationTime")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedDrills.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {},
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => handleRowClick(row.id)}
                >
                  <TableCell className="nowrap">{row.unitCode}</TableCell>
                  <TableCell className="nowrap">{row.addedBy}</TableCell>
                  <TableCell className="nowrap">
                    {new Date(row.activationTime).toLocaleString("tr-TR", {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </TableCell>
                  <TableCell className="nowrap">
                    {new Date(row.completedTime).toLocaleString("tr-TR", {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </TableCell>
                  <TableCell className="nowrap">
                    {calculateTimeDifference(
                      row.activationTime,
                      row.completedTime
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <button
        className="ui button returnToStoreSelection showUnitsReturnBut"
        onClick={(e) => {
          e.preventDefault();
          window.location.href = `${config.managementPath}`;
        }}
      >
        {t("Return")}
      </button>
    </Box>
  );
};
