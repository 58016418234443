import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <h1 className="notFound">{t("404Desc")}</h1>
      <button
        className="ui button returnToStoreSelection"
        onClick={(e) => {
          e.preventDefault();
          window.location.href = "/homepage";
        }}
      >
        {t("ReturnToHomepage")}
      </button>
    </Box>
  );
};
