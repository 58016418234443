import { useEffect, useState } from "react";
import { get, set } from "../../services/cache";
import config from "../../services/config";
import SelectLocation from "../../components/InoutScreens/SelectLocation";
import ExitFromStore from "../../components/InoutScreens/ExitFromStore";
import { useNavigate } from "react-router-dom";

export const Homepage = () => {
  const redirect = useNavigate();

  // STATES
  const [stores, setStores] = useState(null);
  const [loginInfo, setLoginInfo] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [isCaptain, setIsCaptain] = useState(null);
  const [isModerator, setIsModerator] = useState(null);
  const [isInside, setIsInside] = useState(null);
  const [store, setStore] = useState("");

  useEffect(() => {
    const _loginInfo = get("userCache");
    const userAccessToken = get("fedidLoginAccessToken");

    if (
      !_loginInfo ||
      Object.keys(_loginInfo).length === 0 ||
      !userAccessToken
    ) {
      redirect("/");
    }

    const _isInside = _loginInfo.data.action_type;

    setLoginInfo(_loginInfo);
    setIsInside(_isInside);

    const { hasDuty, hasRole, has_moderator_role } = _loginInfo.data;

    setIsAdmin(hasDuty);
    setIsCaptain(hasRole);
    setIsModerator(has_moderator_role);

    // GET LOCATION LIST
    fetch(`${config.baseUrl}/v1/business_unit/list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userAccessToken.data.access_token}`,
        "x-api-key": config.apiKey,
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((data) => setStores(data))
      .catch((error) => console.log(error));
  }, []);

  const handleChange = (event) => {
    const selectedStore = event.target.value;
    setStore(selectedStore);
    set({
      key: "whereIAm",
      data: `${selectedStore.name} - ${selectedStore.unitCode}`,
    });
    set({
      key: "differentSite",
      data: `${selectedStore.unitCode}`,
    });
  };

  const RenderHomepage = isInside ? ExitFromStore : SelectLocation;

  return (
    <RenderHomepage
      stores={stores}
      loginInfo={loginInfo}
      isAdmin={isAdmin}
      isCaptain={isCaptain}
      isModerator={isModerator}
      handleChange={handleChange}
      store={store}
    />
  );
};
