export default {
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  apiKey: process.env.REACT_APP_API_KEY,
  siteUrl: process.env.REACT_APP_BASE_URL,
  managementPath: "mgmtpnl",
  Api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
  },
  FedidCodeVerifier: {
    cacheKey: "fedidCodeVerifier",
  },
  FedidLogin: {
    cacheKey: "fedidLoginAccessToken",
  },
  FedidUrl: {
    cacheKey: "fedidUrl",
  },
};
