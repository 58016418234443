import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { get } from "../../services/cache";
import config from "../../services/config";
import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/tr_TR";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InputLabel from "@mui/material/InputLabel";
import apiClient from "../../services/apiClient";

export const StartDrill = () => {
  const { t } = useTranslation();
  const redirect = useNavigate();

  // STATES
  const [selectedDate, setSelectedDate] = useState("");
  const [activeDrills, setActiveDrills] = useState([]);
  const [stores, setStores] = useState([]);
  const [store, setStore] = React.useState("");
  const [specificLocation, setSpecificLocation] = React.useState("");

  const userCache = get("userCache");
  const userLocation = userCache.data.site;

  const { hasRole } = userCache.data;

  const changeStore = (event) => {
    setStore(event.target.value);
  };

  // CALL START NEW DRILL API
  const startNewDrill = () => {
    const drillData = {
      addedBy: "",
      unitCode: specificLocation || userLocation,
      activationTime: selectedDate,
    };

    apiClient
      .post("/v1/drills", drillData)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => console.error("Error:", error));
  };

  const dateSelect = (date, dateString) => {
    const localDate = new Date(dateString);
    const tuzDate = new Date(localDate.getTime() + 3 * 60 * 60 * 1000);

    setSelectedDate(tuzDate.toISOString());
  };

  const startDrill = (drillId) => {
    apiClient
      .patch(`/v1/drills/start/${drillId}`)
      .then(() => {
        setActiveDrills((prevDrills) =>
          prevDrills.filter((drill) => drill.id !== drillId)
        );
        window.location.reload();
      })
      .catch((error) => console.error("Error:", error));
  };

  const completeDrill = (drillId) => {
    apiClient
      .patch(`/v1/drills/complete/${drillId}`)
      .then(() => {
        setActiveDrills((prevDrills) =>
          prevDrills.filter((drill) => drill.id !== drillId)
        );
        window.location.reload();
      })
      .catch((error) => console.error("Error:", error));
  };

  const deleteDrill = (drillId) => {
    apiClient
      .delete(`/v1/drills/delete/${drillId}`)
      .then(() => {
        setActiveDrills((prevDrills) =>
          prevDrills.filter((drill) => drill.id !== drillId)
        );
        window.location.reload();
      })
      .catch((error) => console.error("Error:", error));
  };

  useEffect(() => {
    const loginInfo = get("userCache");

    const { hasDuty, hasRole, has_moderator_role } = loginInfo.data;

    if (!(hasDuty || hasRole || has_moderator_role)) {
      redirect("/homepage");
    }

    // CALL OTHER STORES LIST
    if (hasRole) {
      apiClient
        .get("/v1/business_unit/list")
        .then((data) => {
          setStores(data);
        })
        .catch((error) => console.log(error));

      apiClient
        .get("/v1/drills")
        .then((data) => {
          const activeDrills = data.data;
          setActiveDrills(activeDrills);
        })
        .catch((error) => console.log(error));
    } else {
      apiClient
        .get(`/v1/drills?unitCode=${userLocation}`)
        .then((data) => {
          const activeDrills = data.data;
          setActiveDrills(activeDrills);
        })
        .catch((error) => console.log(error));
    }
  }, []);

  return (
    <Box>
      <div className="drill-main-container">
        <span className="containerTitle">{t("StartDrillOnMyLocation")}</span>

        {hasRole && (
          <div className="selectOtherStore w-80 flex">
            <FormControl
              className="otherStore"
              fullWidth
              variant="filled"
              sx={{ m: 1, minWidth: 120 }}
            >
              <InputLabel id="demo-simple-select-filled-label">
                {t("SelectLocation")}
              </InputLabel>

              <Select
                id="demo-simple-select-filled"
                labelId="demo-simple-select-filled-label"
                value={store}
                label={t("SelectLocation")}
                onChange={changeStore}
              >
                {stores.map((store) => (
                  <MenuItem
                    key={store.unitCode}
                    value={store}
                    onClick={() => {
                      setSpecificLocation(store.unitCode);
                    }}
                  >
                    {store.name.toUpperCase()} - {store.unitCode}
                  </MenuItem>
                ))}

              </Select>
            </FormControl>
            <Tooltip enterTouchDelay={0} title={t("StartDrillDefaultInfo")}>
              <IconButton>
                <HelpOutlineIcon style={{ color: "#7b7b7b" }} />
              </IconButton>
            </Tooltip>
          </div>
        )}

        <DatePicker
          id="drill-datepicker"
          placeholder={t("SelectDate")}
          locale={{
            ...locale,
            lang: {
              ...locale.lang,
              now: `${t("Now")}`,
              ok: `${t("Select")}`,
            },
          }}
          onChange={dateSelect}
          onClick={() => {
            document.body.style.height = "100vh";
            document.body.style.marginBottom = "50px";
          }}
          showTime
        />


        <button
          className={`ui button returnToStoreSelection showUnitsReturnBut ${Object.keys(selectedDate).length === 0 ? "disabled-button" : ""
            }`}
          onClick={() => {
            if (Object.keys(selectedDate).length === 0) {
              return;
            }
            startNewDrill();
          }}
        >
          {t("PlanIt")}
        </button>
      </div>

      {activeDrills.length > 0 && (
        <div className="drill-main-container">
          <ul className="active-drill-list">
            <span className="containerTitle">{t("ActiveDrills")}</span>
            {activeDrills.map((drill) => (
              <li className="li-drill-item" key={drill.id}>
                <span>
                  <strong>{t("AddedDate")}</strong>{" "}
                  {new Date(drill.addedTime).toLocaleString("tr-TR")}
                </span>
                <span>
                  <strong>{t("StartDate")}</strong>{" "}
                  {new Date(drill.activationTime).toLocaleString("tr-TR")}
                </span>
                <span>
                  <strong>{t("AddedBy")}</strong> {drill.addedBy}
                </span>
                <span>
                  <strong>{t("Location")}:</strong> {drill.unitCode}
                </span>
                <span>
                  <strong>{t("Status")}</strong>{" "}
                  <span className="underline black bold">
                    {drill.drillStatuses}
                  </span>
                </span>
                <span className="list-seperator"></span>
                <div className="drillButtonArea">
                  {drill.drillStatuses !== "Active" && (
                    <button
                      className="drillButtons startDrill"
                      onClick={() => startDrill(drill.id)}
                    >
                      {t("Start")}
                    </button>
                  )}

                  {drill.drillStatuses === "Active" && (
                    <button
                      className="drillButtons completeDrill"
                      onClick={() => completeDrill(drill.id)}
                    >
                      {t("Complete")}
                    </button>
                  )}

                  <button
                    className="drillButtons deleteDrill"
                    onClick={() => deleteDrill(drill.id)}
                  >
                    {t("Delete")}
                  </button>
                </div>
              </li>
            ))}

          </ul>
        </div>
      )}

      <button
        className="ui button returnToUserlist mb-80"
        onClick={() => {
          window.location.href = `${config.managementPath}`;
        }}
      >
        {t("Return")}
      </button>
    </Box>
  );
};
