import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { get } from "../../services/cache";
import { useTranslation } from "react-i18next";

export const DrillManagementPanel = () => {
  const { t } = useTranslation();

  const redirect = useNavigate();
  const loginInfo = get("userCache");
  const isAdmin = loginInfo?.data?.hasRole;
  const isCaptain = loginInfo?.data?.hasDuty;
  const isModerator = loginInfo?.data?.has_moderator_role;

  useEffect(() => {
    if (!isCaptain && !isAdmin && !isModerator) {
      redirect("/homepage");
    }
  }, [redirect, loginInfo, isCaptain, isAdmin, isModerator]);

  const buttonConfigs = [
    { label: t("PlanDrill"), url: "/startdrill" },
    { label: t("DrillList"), url: "/drills" },
  ];

  return (
    <Box>
      <h1 className="welcomeText">{t("DrillManagementPanel")}</h1>
      <div className="loginContainer">
        <div className="admin-buttons">
          {buttonConfigs.map((config, index) => (
            <button
              key={index}
              className="ui button fedidLogin active"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = `${config.url}`;
              }}
            >
              {config.label}
            </button>
          ))}
          <button
            className="ui button returnToStoreSelection"
            onClick={(e) => {
              e.preventDefault();
              redirect(-1);
            }}
          >
            {t("Return")}
          </button>
        </div>
      </div>
    </Box>
  );
};
