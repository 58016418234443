import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import config from "../../services/config";
import { get } from "../../services/cache";
import { TextField } from "@mui/material/";
import WarnAddGuest from "../../components/NoticeScreens/WarnAddGuest";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import apiClient from "../../services/apiClient";

export const AddGuest = () => {
  const { t } = useTranslation();

  const redirect = useNavigate();

  useEffect(() => {
    const loginInfo = get("userCache");

    const hasPermission =
      loginInfo.data.hasDuty ||
      loginInfo.data.hasRole ||
      loginInfo.data.has_moderator_role;

    if (!hasPermission) {
      redirect("/homepage");
    }
  }, [redirect]);

  const [inputs, setInputs] = useState({});
  const [message, setMessage] = useState(false);
  const [inputsEmpty, setInputsEmpty] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await apiClient.post("/v1/business_unit/guest", inputs);

      if (response.status === "200") {
        setMessage(true);
      } else {
        setInputsEmpty(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setInputsEmpty(true);
    }
  };

  return (
    <Box>
      <div className="addGuestContainer">
        <span className="containerTitle">{t("AddGuestDesc")}</span>
        <form className="flexForm" onSubmit={handleSubmit}>
          <TextField
            required
            InputProps={{ inputProps: { maxLength: 18 } }}
            id="outlined-required"
            name="name"
            label={t("Name")}
            onChange={handleChange}
            value={inputs.name || ""}
          />

          <TextField
            required
            sx={{ mt: 3 }}
            InputProps={{ inputProps: { maxLength: 20 } }}
            name="surname"
            id="outlined-required"
            onChange={handleChange}
            value={inputs.surname || ""}
            label={t("Surname")}
          />

          <TextField
            required
            sx={{ mt: 3 }}
            name="note"
            onChange={handleChange}
            InputProps={{ inputProps: { maxLength: 20 } }}
            value={inputs.note || ""}
            id="outlined-required"
            label={t("EnteranceReason")}
          />

          <input
            type="submit"
            name="Sign Up"
            value={t("AddGuest")}
            className={`ui button returnToStoreSelection showUnitsReturnBut ${Object.keys(inputs).length === 0 ? "disabled-button" : ""
              }`}
            onClick={async (e) => {
              e.preventDefault();
              if (Object.keys(inputs).length === 0) {
                setInputsEmpty(true);
              } else if (Object.keys(inputs).length === 3) {
                e.target.disabled = true;
                try {
                  await handleSubmit(e);
                } catch (error) {
                  e.target.disabled = false;
                  console.error("Error:", error);
                  setInputsEmpty(true);
                }
              }
            }}
          />

          {inputsEmpty && (
            <Alert severity="error" sx={{ mt: 2 }} className="addGuestAlert">
              {t("ErrorYouMustFill")}
            </Alert>
          )}
        </form>
      </div>

      {message && <WarnAddGuest />}

      <button
        className="ui button returnToUserlist"
        onClick={() => {
          window.location.href = `${config.managementPath}`;
        }}
      >
        {t("Return")}
      </button>
    </Box>
  );
};
