import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SettingsIcon from "@mui/icons-material/Settings";
import { Text } from "../Text/Text";
import { get, set } from "../../services/cache";
import config from "../../services/config";
import { useTranslation } from "react-i18next";
import { useSpring, animated } from "react-spring";
import TechLogo from "../../assets/logo/dkt-tech-logo-old.png";

export const SelectLocation = (props) => {
  const { t } = useTranslation();
  const [loginInfo, setLoginInfo] = useState(null);
  const [buttonActive, setButtonActive] = useState(false);

  // ANIMATION CSS PROPS
  const animationProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { tension: 50, friction: 20 },
  });

  const userAccessToken = get("fedidLoginAccessToken");

  const setAllowed = () => {
    setButtonActive(true);
  };

  let selectedUnitCode = props.store.unitCode;

  useEffect(() => {
    const _loginInfo = get("userCache");
    setLoginInfo(_loginInfo);
  }, []);

  const defaultEntrance = () => {
    fetch(`${config.baseUrl}/v1/business_unit/enter`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userAccessToken.data.access_token}`,
        "x-api-key": config.apiKey,
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then(() => {
        let updated = {
          ...loginInfo.data,
          action_type: 1,
          differentStore: 0,
          current_site_id: loginInfo.data.site,
          current_site_name: loginInfo.data.site_name,
        };
        set({
          key: "userCache",
          data: updated,
        });
      })
      .catch((error) => console.log(error));
  };

  const selectedEntrance = () => {
    fetch(
      `${config.baseUrl}/v1/business_unit/enter?unitCode=${selectedUnitCode}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userAccessToken.data.access_token}`,
          "x-api-key": config.apiKey,
        },
        credentials: "same-origin",
      }
    )
      .then((response) => response.json())
      .then(() => {
        let updated = {
          ...loginInfo.data,
          action_type: 1,
          differentStore: 1,
        };
        set({
          key: "userCache",
          data: updated,
        });
      })
      .catch((error) => console.log(error));
  };

  return (
    <Box>
      <animated.div style={animationProps}>
        <h1 className="hp-welcome-text">
          <span>{t("WelcomeText")}</span>
          <span>
            {" "}
            {props.loginInfo &&
              props.loginInfo.data.name + " " + props.loginInfo.data.sn}
          </span>
        </h1>
        <div className="loginContainer">
          <div className="buttonArea">
            <span className="containerTitle">{t("SelectLocation")}</span>
            {(props.isAdmin || props.isCaptain || props.isModerator) && (
              <button
                className="adminSettings"
                onClick={() => {
                  window.location.href = `${config.managementPath}`;
                }}
              >
                <SettingsIcon className="svgIcons" />
              </button>
            )}

            <div className="locationFlex currentLocationDiv">
              <p className="currentLocationText">{t("DefaultLocation")}</p>

              <Text
                text={
                  props.loginInfo
                    ? `${props.loginInfo.data.site_name} - ${props.loginInfo.data.site}`
                    : ""
                }
              />
            </div>

            {props.stores && (
              <div className="stores">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {t("DifferentLocation")}
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.store}
                    label="Farklı Bir Konumda Mısınız ?"
                    onChange={props.handleChange}
                  >
                    {props.stores.map((magaza, index) => (
                      <MenuItem key={index} value={magaza} onClick={setAllowed}>
                        {magaza.unitCode} - {magaza.name.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="entranceButtons">
                  <button
                    className="ui button defaultStore"
                    style={buttonActive ? { display: "none" } : {}}
                    onClick={(e) => {
                      e.preventDefault();
                      defaultEntrance();
                      setTimeout(() => {
                        window.location.href = `${config.siteUrl}/homepage`;
                      }, 1000);
                    }}
                  >
                    {t("DefaultLocEnterence")}
                  </button>
                  <button
                    className="ui button otherStoreButton"
                    disabled={!buttonActive}
                    style={
                      buttonActive ? { opacity: 1, cursor: "pointer" } : {}
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      selectedEntrance();
                      setTimeout(() => {
                        window.location.href = `${config.siteUrl}/homepage`;
                      }, 1000);
                    }}
                  >
                    {t("EnterSelectedLoc")}
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="logoContainer">
            <img
              className="techLogo"
              src={TechLogo}
              alt="Decathlon Technology Logo"
            />
          </div>
        </div>
      </animated.div>
    </Box>
  );
};

export default SelectLocation;
